<template>

  <vs-table :data="volunteersData" @selected="showVolunteerDetails"
            :pagination="volunteersData.length > maxItems ? true : false" :max-items="maxItems" search>
    <template slot="header">
      <div class="d-inline-block float-left">
        <h2 v-if="filter === 'active'">Aktivni prostovoljci</h2>
        <h2 v-if="filter === 'onHold'">Prostovoljci trenutno na čakanju</h2>
        <h2 v-if="filter === 'inactive'">Neaktivni prostovoljci</h2>
        <h2 v-if="filter === 'all'">Vsi prostovoljci v vseh enotah</h2>
      </div>
      <vs-chip class="mt-1 ml-3">{{ volunteersData.length }}</vs-chip>
    </template>

    <template slot="thead">
      <vs-th sort-key="id">
        <!-- empty -->
      </vs-th>
      <vs-th>
        Slika
      </vs-th>
      <vs-th sort-key="first_name">
        Ime
      </vs-th>
      <vs-th sort-key="last_name">
        Priimek
      </vs-th>
      <vs-th v-if="filter === 'all'" sort-key="unit_id">
        Enota
      </vs-th>
      <vs-th sort-key="agreementValidityDate">
        Datum veljavnosti dogovora
      </vs-th>
      <vs-th sort-key="travelCostsRefund">
        Povračilo potnih stroškov
      </vs-th>
      <vs-th>
        Akcije
      </vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr v-if="$globalFunctions.isAdmin() && (filter === 'active' || filter === 'all') && volunteersWithAlmostExpiredAgreement.length > 0">
        <vs-td colspan="7">
          <vs-alert color="danger" icon-pack="feather" icon="icon-info" :title="'Prostovoljci, ki jim v roku 1 meseca poteče veljavnost Dogovora o prostovoljskem delu'">
            <ul>
              <li v-for="(volunteer, index) in volunteersWithAlmostExpiredAgreement" :key="'warning_' + volunteer.id" style="float: none; clear: both;" class="py-3">
                <div style="float: left;">
                  {{index+1}}. <b>{{volunteer.first_name + ' ' + volunteer.last_name}}</b><span v-if="filter === 'all'"> - {{getUnitDataById(volunteer.unit_id)}}</span>; dogovor veljaven do {{moment(volunteer.agreementValidityDate).format('DD. MM. YYYY')}}
                </div>
                <vs-button color="success" icon="print" size="small" class="float-left py-1 ml-3" v-if="filter === 'active'"
                           @click.stop="() => { printDogovorOprostovoljskemDelu(volunteer); }">
                  Natisni dogovor prostovoljskem delu
                </vs-button>

                <vs-button color="primary" icon="edit" size="small" class="float-left py-1 ml-3" v-if="filter === 'active'"
                           @click.stop="() => { $router.push({name: 'Prostovoljec', params: {volunteerId: volunteer.id}}) }">
                  Uredi
                </vs-button>
              </li>
              <li>
                <div class="clearfix my-3"></div>
              </li>
            </ul>

          </vs-alert>
        </vs-td>
      </vs-tr>
      <vs-tr :key="'active_' + index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">

        <vs-td :data="data[index]">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ index + 1 }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].profile_image">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <div class="avatarWithStatus">
              <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(data[index].profile_image)"/>
              <vs-avatar v-if="data[index].active === 1" size="small" class="status" color="success"
                         icon="check_circle"/>
              <vs-avatar v-if="data[index].active === 0" size="small" class="status" color="danger"
                         icon="radio_button_unchecked"/>
            </div>

          </vs-col>
        </vs-td>

        <vs-td :data="data[index].first_name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ data[index].first_name }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].last_name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ data[index].last_name }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].unit_id" v-if="filter === 'all'">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ getUnitDataById(data[index].unit_id) }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].agreementValidityDate">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{
              data[index].agreementValidityDate ? moment(data[index].agreementValidityDate).format('DD. MM. YYYY') :
                'datum ni določen'
            }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].travelCostsRefund">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            <vs-avatar size="small" :icon="'done'" v-if="data[index].travelCostsRefund"
                       :style="'background-color: #28c76f'"/>
            <vs-avatar size="small" :icon="'close'" v-if="!data[index].travelCostsRefund"
                       :style="'background-color: #c3c3c3'"/>
          </vs-col>
        </vs-td>

        <vs-td :data="data[index]" @mousedown.stop="stopTheEvent" v-if="!$globalFunctions.isAdmin()">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <vs-button color="primary" icon="edit"
                       @click.stop="() => { $router.push({name: 'Prostovoljec', params: {volunteerId: data[index].id}}) }">
              Uredi
            </vs-button>

            <vs-button @click.stop="" :href="'tel://' + data[index].phone" class="ml-1" color="warning" icon="phone">Pokliči</vs-button>

          </vs-col>
        </vs-td>
        <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" v-if="$globalFunctions.isAdmin()">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            <vs-dropdown>
              <vs-button class="btn-drop" type="filled" icon="expand_more">Akcije</vs-button>

              <vs-dropdown-menu>
                <vs-dropdown-item
                  :href="'mailto:' + data[index].email + '?subject=Dobrodo%C5%A1li%20pri%20Sopotnikih&body=Pozdravljeni%2C%20' + data[index].first_name + '.%0A%0ADobrodo%C5%A1li%20v%20ekipi%20prostovoljcev%20Zavoda%20Sopotniki%21%20Zelo%20smo%20veseli%2C%20da%20ste%20se%20nam%20pridru%C5%BEili%21%0A%0AZa%20dostop%20do%20Prostovoljskega%20portala%2C%20je%20potrebna%20registracija.%20Registrirate%20se%20na%20tej%20povezavi%3A%0Ahttps%3A%2F%2Fprostovoljci.sopotniki.org%2F%23%2Fregistracija%3Femail%3D' + data[index].email + '%0A%0AKo%20uspe%C5%A1no%20opravite%20registracijo%2C%20do%20portala%20dostopate%20na%20povezavi%3A%0Ahttps%3A%2F%2Fprostovoljci.sopotniki.org%0A%0A%C5%BDelim%20vam%20vso%20sre%C4%8Do%20na%20poti%2C%20varno%20in%20preudarno.%20Naj%20prostovoljstvo%20pri%20Sopotnikih%20tudi%20vam%20prinese%20polno%20lepih%20do%C5%BEivetij%20in%20izku%C5%A1enj%21%0A%0ALep%20pozdrav%21'">
                  Pošlji dostopne podatke
                </vs-dropdown-item>
                <vs-dropdown-item divider @click="printIdCard(data[index])">Natisni izkaznico</vs-dropdown-item>
                <vs-dropdown-item @click="printDogovorOprostovoljskemDelu(data[index])">Natisni Dogovor o prostovoljskem
                  delu
                </vs-dropdown-item>
<!--                <vs-dropdown-item @click="printCovidTestiranjeProstovoljca(data[index])">Natisni Potrdilo za COVID-19 test-->
<!--                </vs-dropdown-item>-->
                <vs-dropdown-item @click="printIzjavaProstovoljca(data[index])">Natisni Izjavo prostovoljca
                </vs-dropdown-item>
                <vs-dropdown-item @click="printDovoljenjeZaUporaboVozila(data[index])"> Natisni Dovoljenje za uporabo
                  službenega vozila
                </vs-dropdown-item>
                <vs-dropdown-item @click="printPristopnaIzjavaZaZavarovanje(data[index])"> Natisni Pristopno izjavo za
                  zavarovanje
                </vs-dropdown-item>
                <vs-dropdown-item @click="printIzjavaOprejemuVrnitviOpreme(data[index])" divider>Natisni Izjavo o
                  prejemu/vrnitvi opreme
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <vs-button @click.stop="" :href="'tel://' + data[index].phone" class="ml-1" color="warning" icon="phone">Pokliči</vs-button>

          </vs-col>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>

</template>

<script>
import Vue from 'vue';


export default {
  name: 'volunteers-table',
  props: {
    volunteersData: Array,
    filter: String
  },
  components: {},

  data() {
    return {
      maxItems: 500,
      volunteersWithAlmostExpiredAgreement: []
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    if(_this.$globalFunctions.isAdmin() && (_this.filter === 'active' || _this.filter === 'all')) {
      _this.checkForExpiredAgreements();
    }

  },

  destroyed() {
    console.log("destroyed");
    const _this = this;

  },

  beforeDestroy() {
    console.log("beforeDestroy");
    const _this = this;


  },

  methods: {

    showVolunteerDetails(tr) {
      const _this = this;

      _this.$emit('showVolunteerDetails', tr);
    },

    getUnitDataById(unitId) {
      const _this = this;

      if (localStorage.getItem('userInfo')) {
        let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
        if (unit) {
          return unit.name + ' (' + unit.coveredMunicipalities + ')';
        } else
          return 'podatka ni bilo mogoče pridobiti';
      }

    },

    printIdCard(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/id-card/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `prostovoljska_izkaznica_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printDogovorOprostovoljskemDelu(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/dogovor-o-prostovoljskem-delu/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `dogovor_o_prostovoljskem_delu_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printIzjavaProstovoljca(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/izjava-prostovoljca/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `izjava_prostovoljca_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printCovidTestiranjeProstovoljca(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/covid-testiranje-prostovoljca/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `potrdilo_covid_19_test_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    printDovoljenjeZaUporaboVozila(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/dovoljenje-za-vozilo/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `dovoljenje_za_vozilo_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printPristopnaIzjavaZaZavarovanje(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/pristopna-izjava-za-zavarovanje/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `pristopna_izjava_za_zavarovanje_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printIzjavaOprejemuVrnitviOpreme(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/izjava-o-prejemu-opreme/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `izjava_o_prejemu_opreme_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    stopTheEvent: (event) => {
      event.stopPropagation();
      event.preventDefault();
    },

    checkForExpiredAgreements() {
      const _this = this;
      let startDate = _this.moment(new Date()).subtract(1, 'days').format('MM.DD.YYYY');
      let endDate = _this.moment(new Date()).add(30, 'days').format('MM.DD.YYYY');

      _.each(_this.volunteersData, function (key, index) {

        let validity = _this.moment(key.agreementValidityDate).format('MM.DD.YYYY');
        let isActive = key.active;

        if (_this.moment(validity).isBetween(startDate, endDate) && isActive == 1) {
          _this.volunteersWithAlmostExpiredAgreement.push(key);
        }
      });

      _this.volunteersWithAlmostExpiredAgreement = _.orderBy(_this.volunteersWithAlmostExpiredAgreement, [
        function (item) {
          return item.agreementValidityDate
        },
      ], ["asc"]);

    }

  }
}
</script>

