<template>
  <div id="unitsContainer">
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Prostovoljci</li>
    </vs-breadcrumb>


    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn" title="Dodaj novega uporabnika" @click.stop="addVolunteer()"></vs-button>

    <div>


      <vs-tabs v-if="activeVolunteersData && inactiveVolunteersData">
        <vs-tab label="Aktivni" icon="check_circle" color="success" @click="">
          <UpcomingBirthdays :volunteers-data="activeVolunteersData"></UpcomingBirthdays>
          <VolunteersTable :volunteersData="activeVolunteersData" :filter="'active'" @showVolunteerDetails="showVolunteerDetails" />
        </vs-tab>
        <vs-tab label="Na čakanju" icon="pause_circle" color="success" @click="">
          <VolunteersTable :volunteersData="onHoldVolunteersData" :filter="'onHold'" @showVolunteerDetails="showVolunteerDetails" />
        </vs-tab>
        <vs-tab label="Neaktivni" icon="radio_button_unchecked" @click="">
          <VolunteersTable :volunteersData="inactiveVolunteersData" :filter="'inactive'" @showVolunteerDetails="showVolunteerDetails" />
        </vs-tab>
        <vs-tab label="Vsi prostovoljci v vseh enotah" icon="done_all" @click="" v-if="allVolunteersData">
          <VolunteersTable :volunteersData="allVolunteersData" :filter="'all'" @showVolunteerDetails="showVolunteerDetails" />
        </vs-tab>
      </vs-tabs>

    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="volunteerSidebar" spacer
                  v-model="sidebarActive">

        <Volunteer v-if="volunteerId && sidebarActive" :volunteerId="volunteerId"/>
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import Volunteer from "../../components/Sopotniki/volunteers/Volunteer";
  import VolunteersTable from "../../components/Sopotniki/volunteers/VolunteersTable";
  import UpcomingBirthdays from "@/components/Sopotniki/volunteers/UpcomingBirthdays";


  export default {
    name: 'volunteers',

    components: {
      VolunteersTable,
      Volunteer,
      UpcomingBirthdays
    },

    data() {
      return {
        volunteersWithUpcomingBirthdays: [],
        activeVolunteersData: null,
        onHoldVolunteersData: null,
        inactiveVolunteersData: null,
        allVolunteersData: null,

        volunteerId: null,
        sidebarActive: false,
      }
    },

    computed: {},

    mounted() {
      const _this = this;
      console.log("HERE!");
      console.log("_this.$globalFunctions.isAdmin()!", _this.$globalFunctions.isAdmin());
      if(_this.$globalFunctions.isAdmin()) {
        _this.getAllVolunteers();
      } else {
        _this.getVolunteersForUnit();
      }
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.volunteerId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      getAllVolunteers() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers')
          .then((res) => {

            console.log("---->", res.data.data);

            _this.volunteersData = res.data.data;
            _this.activeVolunteersData = _.filter(res.data.data, (volunteer) => volunteer.unit_id === _this.$globalFunctions.getCurrentUnit().id && volunteer.active === 1 && !volunteer.on_hold);
            _this.onHoldVolunteersData = _.filter(res.data.data, (volunteer) => volunteer.unit_id === _this.$globalFunctions.getCurrentUnit().id && volunteer.active === 1 && volunteer.on_hold);
            _this.inactiveVolunteersData = _.filter(res.data.data, (volunteer) => volunteer.unit_id === _this.$globalFunctions.getCurrentUnit().id && volunteer.active === 0);
            _this.allVolunteersData = res.data.data;

            console.log("activeVolunteersData: ", _this.activeVolunteersData);
            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      getVolunteersForUnit() {
        const _this = this;

        _this.$vs.loading();
        console.log("_this.$globalFunctions.getCurrentUnit().id", _this.$globalFunctions.getCurrentUnit().id);

        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
          .then((res) => {

            console.log("---->", res.data.data);

            _this.volunteersData = res.data.data;
            _this.activeVolunteersData = _.filter(res.data.data, (volunteer) => volunteer.active === 1);
            _this.inactiveVolunteersData = _.filter(res.data.data, (volunteer) => volunteer.active === 0);

            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showVolunteerDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.volunteerId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      stopTheEvent: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },

      addVolunteer() {
        const _this = this;

        _this.$router.push({name: 'Prostovoljec'});
      }


    }
  }
</script>

