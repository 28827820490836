<template>
  <div id="volunteerContainer" class="relative">

    <div class="loadingContainer">
      <div v-if="volunteerData">


        <vs-list>
          <vs-list-item class="volunteer"
                        :title="volunteerData.first_name + ' ' + volunteerData.last_name"
                        :subtitle="volunteerData.notes">

            <template slot="avatar">
              <div class="avatarWithStatus">
                <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(volunteerData.profile_image)"/>
                <vs-avatar v-if="volunteerData.active === 1" size="small" class="status" color="success"
                           icon="check_circle"/>
                <vs-avatar v-if="volunteerData.active === 0" size="small" class="status" color="danger"
                           icon="radio_button_unchecked"/>
              </div>
            </template>

          </vs-list-item>

          <vs-list-header icon="home"
                          :title="volunteerData.gender === 'male' ? 'Kontaktni podatki prostovoljca' : 'Kontaktni podatki prostovoljke'"
                          color="info"></vs-list-header>
          <vs-list-item :title="volunteerData.addresses.quick_search" icon="location_on"></vs-list-item>
          <vs-list-item :title="volunteerData.phone" :subtitle="'Telefonska številka'"
                        icon="call">
            <vs-button :href="'tel://' + volunteerData.phone" size="small" color="warning" icon="phone">Pokliči</vs-button>
          </vs-list-item>
          <vs-list-item :title="volunteerData.email ? volunteerData.email : 'ne uporablja emaila'"
                        :subtitle="'E-naslov'" icon="email"></vs-list-item>

          <!--          <div class="flex mt-5">-->
          <!--            <div class="w-full">-->
          <!--              <ConsentsWidget :user-id="volunteerData.id" :consent-giver="'VOLUNTEER'" :mode="'view'" v-if="volunteerData.id"></ConsentsWidget>-->
          <!--            </div>-->
          <!--          </div>-->
        </vs-list>

        <vs-row
          v-if="$globalFunctions.isAdmin()"
          vs-align="center"
          vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              :href="'mailto:' + volunteerData.email + '?subject=Dobrodo%C5%A1li%20pri%20Sopotnikih&body=Pozdravljeni%2C%20' + volunteerData.first_name + '.%0A%0ADobrodo%C5%A1li%20v%20ekipi%20prostovoljcev%20Zavoda%20Sopotniki%21%20Zelo%20smo%20veseli%2C%20da%20ste%20se%20nam%20pridru%C5%BEili%21%0A%0AZa%20dostop%20do%20Prostovoljskega%20portala%2C%20je%20potrebna%20registracija.%20Registrirate%20se%20na%20tej%20povezavi%3A%0Ahttps%3A%2F%2Fprostovoljci.sopotniki.org%2F%23%2Fregistracija%3Femail%3D' + volunteerData.email + '%0A%0AKo%20uspe%C5%A1no%20opravite%20registracijo%2C%20do%20portala%20dostopate%20na%20povezavi%3A%0Ahttps%3A%2F%2Fprostovoljci.sopotniki.org%0A%0A%C5%BDelim%20vam%20vso%20sre%C4%8Do%20na%20poti%2C%20varno%20in%20preudarno.%20Naj%20prostovoljstvo%20pri%20Sopotnikih%20tudi%20vam%20prinese%20polno%20lepih%20do%C5%BEivetij%20in%20izku%C5%A1enj%21%0A%0ALep%20pozdrav%21'">
              Pošlji dostopne podatke
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printIdCard(volunteerData)">
              Natisni izkaznico
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printDogovorOprostovoljskemDelu(volunteerData)">
              Natisni Dogovor o prostovoljskem
              delu
            </vs-button>
          </vs-col>
<!--          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">-->
<!--            <vs-button-->
<!--              style="width: 100%"-->
<!--              block-->
<!--              @click="printCovidTestiranjeProstovoljca(volunteerData)">-->
<!--              Natisni Potrdilo za COVID-19 test-->
<!--            </vs-button>-->
<!--          </vs-col>-->
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printIzjavaProstovoljca(volunteerData)">
              Natisni Izjavo prostovoljca
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printDovoljenjeZaUporaboVozila(volunteerData)">
              Natisni Dovoljenje za uporabo
              službenega vozila
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printPristopnaIzjavaZaZavarovanje(volunteerData)">
              Natisni Pristopno izjavo za
              zavarovanje
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" class="mt-1">
            <vs-button
              style="width: 100%"
              block
              @click="printIzjavaOprejemuVrnitviOpreme(volunteerData)">
              Natisni Izjavo o
              prejemu/vrnitvi opreme
            </vs-button>
          </vs-col>
        </vs-row>

        <vs-row
          vs-align="center"
          vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button color="primary" icon="edit" @click.stop="editVolunteer()" class="mt-5">Uredi</vs-button>
          </vs-col>
          <!--          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">-->
          <!--            <vs-button color="danger" icon="delete">Izbriši</vs-button>-->
          <!--          </vs-col>-->
        </vs-row>


      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import ConsentsWidget from "../consentManagement/ConsentWidget";

export default {
  name: 'reservations-calendar',

  components: {
    ConsentsWidget,
    DirectionsRenderer
  },
  props: {
    volunteerId: Number
  },
  data() {
    return {
      volunteerData: null,
      allVolunteers: [],
      unitManager: null
    }
  },

  computed: {},

  mounted() {
    const _this = this;


    _this.getUnit();

  },

  watch: {
    volunteerId: {
      handler(val, oldVal) {
        console.log("CHANGE!!!!");
        this.volunteerData = null;


        if (val) {
          this.getUnit();
        }
      }
    }
  },

  methods: {
    getUnit() {
      const _this = this;


      this.$vs.loading({
        container: '#volunteerContainer .loadingContainer',
      })


      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.volunteerId)
        .then((res) => {

          _this.volunteerData = res.data.data;
          _this.$vs.loading.close('#volunteerContainer .loadingContainer > .con-vs-loading');

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close('#volunteerContainer .loadingContainer > .con-vs-loading');
          return false
        });
    },

    editVolunteer() {
      const _this = this;
      _this.$router.push({name: 'Prostovoljec', params: {volunteerId: _this.volunteerData.id}});
    },

    printIdCard(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/id-card/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `prostovoljska_izkaznica_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printDogovorOprostovoljskemDelu(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/dogovor-o-prostovoljskem-delu/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `dogovor_o_prostovoljskem_delu_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printIzjavaProstovoljca(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/izjava-prostovoljca/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `izjava_prostovoljca_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printCovidTestiranjeProstovoljca(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/covid-testiranje-prostovoljca/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `potrdilo_covid_19_test_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    printDovoljenjeZaUporaboVozila(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/dovoljenje-za-vozilo/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `dovoljenje_za_vozilo_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printPristopnaIzjavaZaZavarovanje(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/pristopna-izjava-za-zavarovanje/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `pristopna_izjava_za_zavarovanje_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

    printIzjavaOprejemuVrnitviOpreme(volunteer) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/volunteer/izjava-o-prejemu-opreme/' + volunteer.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `izjava_o_prejemu_opreme_${volunteer.first_name}_${volunteer.last_name}.pdf`
          link.click();
          _this.$vs.loading.close();
        });

    },

  }
}
</script>

